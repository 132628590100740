import {
  type ClientLoaderFunctionArgs,
  redirect,
  useLoaderData,
} from '@remix-run/react';
import { useState } from 'react';
import { useEffectOnce } from 'react-use';

import { type DtoPageSection } from '@lp-lib/api-service-client/public';

import {
  pageSectionAnchor,
  selectSectionByAnchor,
} from '../../app/components/DynamicPage/utils';
import { HomePublicLibraryIndex } from '../../app/components/PublicLibrary/HomePublicLibraryIndex';
import { HomePublicLibraryNav } from '../../app/components/PublicLibrary/HomePublicLibraryNav';
import { useAppAnalytics } from '../analytics/app/identifiable';
import { OnboardingTasksRow } from '../components/Onboarding';
import { OnboardingCall } from '../components/Onboarding/OnboardingCall';
import { useMyOrganizer } from '../components/Organization/hooks/organization';
import { PublicHomeLayoutV2 } from '../components/PublicHome/PublicHomeLayout';
import { apiService } from '../services/api-service';
import { OrganizerRoleUtils } from '../types';
import { usePublicHomeData } from './home.client';

export async function clientLoader(action: ClientLoaderFunctionArgs) {
  const { tag } = action.params;
  if (tag) {
    throw redirect(`/tags/${tag}`);
  }

  const featuredItems = (await apiService.page.getFeatured({ size: 8 })).data
    .items;
  const programs = (await apiService.program.getActivePrograms()).data
    .activePrograms;

  return {
    featuredItems,
    programs,
  };
}

export function shouldRevalidate() {
  return false;
}

export function Component(): JSX.Element {
  const { page, pageName } = usePublicHomeData();
  const data = useLoaderData<typeof clientLoader>();

  const [focusedSection, setFocusedSection] = useState<DtoPageSection | null>(
    () => (page.sections && page.sections.length > 0 ? page.sections[0] : null)
  );
  const organizer = useMyOrganizer();
  const analytics = useAppAnalytics();

  useEffectOnce(() => {
    const targetSection = selectSectionByAnchor(
      page.sections,
      window.location.hash
    );
    if (!targetSection) return;

    setFocusedSection(targetSection);
    const element = document.getElementById(pageSectionAnchor(targetSection));
    if (element) {
      element.scrollIntoView({ behavior: 'instant', block: 'start' });
    }
  });

  return (
    <PublicHomeLayoutV2
      secondaryNav={
        <HomePublicLibraryNav
          pageName={pageName}
          page={page}
          isSectionSelected={(section) => section.id === focusedSection?.id}
        />
      }
    >
      {organizer &&
        organizer.organization &&
        OrganizerRoleUtils.isOwnerOrAdmin(organizer.role) && (
          <>
            <OnboardingTasksRow organization={organizer.organization} />
            <OnboardingCall organization={organizer.organization} />
          </>
        )}

      <HomePublicLibraryIndex
        page={page}
        pageName={pageName}
        setFocusedSection={setFocusedSection}
        {...data}
        analytics={analytics}
      />
    </PublicHomeLayoutV2>
  );
}
