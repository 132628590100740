import { isBefore } from 'date-fns';
import { useState } from 'react';
import { useEffectOnce } from 'react-use';

import {
  EnumsOrgSubscriptionPlan,
  EnumsOrgSubscriptionStatus,
} from '@lp-lib/api-service-client/public';

import { useOnboardingAnalytics } from '../../analytics/onboarding';
import { getQueryParam } from '../../hooks/useQueryParam';
import { apiService } from '../../services/api-service';
import { type Organization } from '../../types';
import { getStaticAssetPath } from '../../utils/assets';
import { booleanify } from '../../utils/common';
import { Tooltip } from '../common/Tooltip';
import { LunaParkLogo } from '../icons/LogoIcon';
import { QuestionMarkIcon } from '../icons/QuestionMarkIcon';
import { HubspotMeetingsForm } from '../Marketing/HubspotMeetingsForm';
import { useUpdateMyOrganization } from '../Organization/hooks/organization';

const ONBOARDING_CALL_DISMISSED_KEY = 'onboarding-call-dismissed';

function Internal(props: { organization: Organization; onClose: () => void }) {
  const { organization, onClose } = props;

  const analytics = useOnboardingAnalytics();
  const updateMyOrganization = useUpdateMyOrganization();
  const [scheduled, setScheduled] = useState(false);

  useEffectOnce(() => {
    analytics.trackOnboardingCallViewed();
  });

  const handleSchedule = async () => {
    analytics.trackOnboardingCallScheduled();

    const resp = await apiService.organization.updateOrgSettings(
      organization?.id || '',
      {
        onboardingCallScheduled: true,
      }
    );
    updateMyOrganization(resp.data.organization);
    setScheduled(true);
  };

  const handleDismiss = () => {
    analytics.trackOnboardingCallDismissed();
    sessionStorage.setItem(ONBOARDING_CALL_DISMISSED_KEY, 'true');
    onClose();
  };

  const hubspotUrl = new URL(
    'https://meetings.hubspot.com/melanie-sturgis/onboarding-request?embed=true'
  );
  hubspotUrl.searchParams.set('team_size', String(organization.maxSize));

  return (
    <div className='fixed inset-0 z-50 bg-lp-black-004 backdrop-blur-sm overflow-auto scrollbar'>
      <div className='w-full h-15 flex items-center pl-10'>
        <LunaParkLogo className='w-27 h-7.5' />
      </div>
      {scheduled ? (
        <div className='mt-25 flex justify-center text-white'>
          <div className='w-140 bg-modal rounded-2.5xl border border-secondary px-16 py-10 gap-10 flex flex-col items-center'>
            <div className='text-tertiary font-medium text-2xl text-center'>
              Your call has been scheduled!
            </div>
            <img
              alt='calendar'
              src={getStaticAssetPath('images/calendar-3d-icon.png')}
              className='w-25 h-25'
            />
            <div className='text-center w-72'>
              <strong>
                You’ll receive an email invite with a link to the call.
              </strong>
              <br />
              In the meantime, check out our full library of team building
              experiences!
            </div>

            <button
              type='button'
              onClick={onClose}
              className='w-96 h-12.5 btn-primary flex items-center justify-center'
            >
              Browse Library
            </button>
          </div>
        </div>
      ) : (
        <>
          <div className='mt-5 w-full h-12 bg-tertiary text-xl font-bold text-black flex justify-center items-center'>
            Booking an onboarding call is highly recommended before accessing
            the Luna Park library
            <div className='relative ml-1 group flex justify-center'>
              <QuestionMarkIcon className='w-4 h-4 fill-current' />
              <div className='absolute invisible group-hover:visible z-5 bottom-full -mb-1'>
                <Tooltip
                  position={'top'}
                  backgroundColor='black'
                  borderRadius={12}
                  arrowWidth={12}
                  borderColor={'rgba(255, 255, 255, 0.4)'}
                  borderWidth={1}
                >
                  <p className='w-70 p-3 text-3xs text-white'>
                    We’ve found that this quick onboarding call is the best way
                    to ensure program success and can drive over 300% higher
                    team engagement
                  </p>
                </Tooltip>
              </div>
            </div>
          </div>
          <div className='w-full flex justify-center gap-12 text-white p-2'>
            <div className='w-90 pt-8'>
              <h2 className='text-lg font-bold text-lp-gray-001'>
                Speak with an Engagement Specialist
              </h2>
              <p className='mt-1 text-base text-icon-gray'>
                Simply select a date & time for us to connect with you.
              </p>
              <div className='mt-9 w-full bg-dark-gray rounded-1.5lg px-5 py-4'>
                <p className='text-green-001 font-bold'>
                  This short call will help you get set up for success and
                  answer any questions!
                </p>
                <ul className='mt-8 list-disc list-inside text-sms'>
                  <li>A 30 minute Zoom call</li>
                  <li>Luna Park walkthrough + FAQs</li>
                  <li>Feel free to bring a colleague</li>
                </ul>
                <button
                  type='button'
                  onClick={handleDismiss}
                  className='mt-5 w-78 h-12.5 btn-secondary'
                >
                  Remind me next time
                </button>
              </div>
            </div>

            <HubspotMeetingsForm
              title='Schedule Your Onboarding Call'
              src={hubspotUrl.toString()}
              onSubmitted={handleSchedule}
              className={`w-210 h-176`}
            />
          </div>
        </>
      )}
    </div>
  );
}

function shouldShowOnboardingCall(organization: Organization) {
  if (booleanify(getQueryParam('force-show-onboarding-call'))) return true;

  if (isBefore(new Date(organization.createdAt), new Date(2024, 7, 7)))
    return false;
  if (
    organization.subscription.plan ===
    EnumsOrgSubscriptionPlan.OrgSubscriptionPlanFree
  )
    return false;
  if (
    organization.subscription.status ===
    EnumsOrgSubscriptionStatus.OrgSubscriptionStatusTrialing
  )
    return false;
  if (organization.maxSize === null || organization.maxSize <= 10) return false;
  if (organization.settings?.onboardingCallScheduled) return false;
  if (sessionStorage.getItem(ONBOARDING_CALL_DISMISSED_KEY)) return false;

  return true;
}

export function OnboardingCall(props: { organization: Organization }) {
  const { organization } = props;

  const [show, setShow] = useState(() =>
    shouldShowOnboardingCall(organization)
  );

  if (!show) return null;
  return (
    <Internal organization={organization} onClose={() => setShow(false)} />
  );
}
